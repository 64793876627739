import React, {useContext} from 'react';
import {CarouselArtist, SideTitle, Banner, Button} from "../../../index";
import styles from './CaseHistory.module.css'
import {AppContext} from "../../../../context/AppContext";
import {Link} from "react-router-dom";

const CaseHistory = () => {
    const appData = useContext(AppContext);


    return (
        <>
            <section className={styles.container} id={"case-history"}>
                <SideTitle children={"CASE HISTORY"}/>
                <CarouselArtist artists={appData.rosterList.artists}/>
                <Banner
                    rigth={false}
                >
                    <div className={styles.text}>Sei un artista emergente? Fatti notare inviando la tua candidatura all'<b>Insert Coin</b>, il format di scouting col quale abbiamo scoperto <i>Madame</i>, <i>Leon Faun</i>, <i>gIANMARIA</i> e tanti altri.</div>
                    <a href="https://insertcoin.aarmusic.it/">
                        <Button>Insert Coin</Button>
                    </a>
                </Banner>
            </section>
        </>
    );
};

export default CaseHistory;