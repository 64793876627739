import React from 'react';
import {Banner, CenterTitle, HeaderStudio, Team} from "../../components";
import styles from "./Studio.module.css";
import Services from "../../components/Pages/Studio/Services/Services";
import bgStudioA from '../../data/img/studio/header.webp';
import bgStudioB from '../../data/img/studio/header1.webp';
import bgHeader from '../../data/img/studio/bgorso.webp';
import logoSony from '../../data/img/customers/sony.png';
import logoUMG from '../../data/img/customers/umg.png';
import logoWarner from '../../data/img/customers/warner.png';
import logoThaurus from '../../data/img/customers/thaurus.png';
import logoThamsanqa from '../../data/img/customers/thamsanqa.png';
import logoFMC from '../../data/img/customers/fmc.png';
import logoOrangle from '../../data/img/customers/orangle.png';
import schedaRegiaA from "../../data/regiaA.pdf";
import schedaRegiaB from "../../data/regiaB.pdf";

const Studio = () => {
    return (
        <>
            <div className={styles.container} style={{backgroundColor: `#000000`, marginTop:`-20vh`}}>
                <img style={{width: `100%`}} src={bgHeader} />
                <center>
                    <h3 style={{color: `#fff`}}>We worked for:</h3><br />
                    <img style={{width: `100px`, margin: `0px 15px 10px 20px`}} src={logoUMG} />
                    <img style={{width: `60px`, margin: `0px 20px`}} src={logoThaurus} />
                    <img style={{width: `80px`, margin: `0px 20px 10px 20px`}} src={logoWarner} />
                    <img style={{width: `60px`, margin: `0px 10px`}} src={logoSony} />
                    <img style={{width: `100px`, margin: `0px 20px 18px 20px`}} src={logoThamsanqa} />
                    <img style={{width: `80px`, margin: `0px 10px 5px 10px`}} src={logoFMC} />
                    <img style={{width: `60px`, margin: `0px 10px 5px 10px`}} src={logoOrangle} />
                </center>
            </div>

            <HeaderStudio title={"Room A"} linkTitle={schedaRegiaA} bgImage={bgStudioA} margin={"-20vh"}/>
            <div className={styles.black}/>
            <HeaderStudio title={"Room B"} linkTitle={schedaRegiaB} bgImage={bgStudioB} margin={"0"}/>
            <div className={styles.text}>
                Underbear Studio si trova in <i>via Dario Papa 4, Milano (MI)</i>. La stazione della metropolitana più vicina è <b>Istria</b> (Metro Lilla), oppure si può arrivare in 15 minuti a piedi scendendo a <b>Sondrio</b> (Metro Gialla).
            </div>
            <Services/>
        </>
    );
};

export default Studio;