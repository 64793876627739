import React from 'react';
import styles from './Contatti.module.css'
import Pianta from '../../../data/img/pianta.png'
import {Link} from "react-router-dom";
import {Device, useMediaQuery} from "../../../hooks";


const Details = () => {
    const isMobile = useMediaQuery(Device.sm)
    const isTablet = useMediaQuery(Device.lg)
    return (
        <>
            <div className={styles.container}>
                <div className={styles.details}>
                    <div className={styles.section}>
                        <div className={styles.subsection}>
                            Per sottoporre delle demo utilizza l’<a href={"https://insertcoin.aarmusic.it"} className={styles.link}>Insert
                            Coin</a>
                        </div>
                        {/* <div className={styles.subsection}>
                            Per lavorare nel nostro studio visita la pagina <Link to={"/studio"} className={styles.link}>Studio</Link>
                        </div> */}
                        {/* <div className={styles.subsection}>
                            Per informazioni sui <a href="studio" target="_blank" className={styles.link}>servizi</a> offerti dal nostro studio scrivi a <a
                            href={"mailto:studio@aarmusic.it"} className={styles.link}>studio@aarmusic.it</a>
                        </div> */}
                        <div className={styles.subsection}>
                            Per informazioni sui servizi offerti dal nostro studio scrivi a <a
                            href={"mailto:studio@aarmusic.it"} className={styles.link}>studio@aarmusic.it</a>
                        </div>
                        <div className={styles.subsection}>
                            Per richieste di collaborazioni, informazioni, sync o altro scrivi a <a
                            href={"mailto:info@aarmusic.it"} className={styles.link}>info@aarmusic.it</a>
                        </div>
                    </div>
                    {isMobile || isTablet ? null : <img src={Pianta} alt={"all-artists"} className={styles.image}/>
                    }

                </div>
            </div>
        </>
    );
};

export default Details;