import React, {useState} from 'react';
import styles from './Feed.module.css'
import {CenterTitle} from "../../../index";
import Button from "../../../Button/Button";
import Instagram from "./Instagram";


const Feed = () => {
    const [show, setShow] =  useState(true);
    return (
        <>
            <section>
                {show ? <><CenterTitle children={"NEWS"}/>
                    <div className={styles.container}>
                        <Instagram
                            token={'IGQWRNRVI4c2I0V0FDVmVybXczTnFNY2RJMGpsaDVsTDhmQlFtSW1pQ1Y2cnNuVl9sOWplRHppQ3oya2Nqb0lJUHpmNGNWRFQ4b2t1LTEyTFhrc2ZAFOS1xY3dRdHJnN05MMEFhTVNOSzczRGFKR3lrS0NxSGpVYm8ZD'}
                            limit={10}
                            onChangeShow={setShow}
                        />


                        <a href={"https://www.instagram.com/aarmusiclabel/"} target={"_blank"}>
                            <Button buttonStyle={"black2"}>
                                Altre News
                            </Button>
                        </a>
                    </div></> : null}

            </section>

        </>
    );
};

export default Feed;