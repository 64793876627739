import React from 'react';
import styles from './Home.module.css'
import {Header, CaseHistory, Feed} from "../../components";
import VideoBG from '../../data/videos/header-video.mp4'

const Home = () => {
    return (
        <>
           {/* <video src={VideoBG} autoPlay loop muted className={styles.videoBG}/>*/}
            <Header/>
            <CaseHistory/>
            <Feed/>
        </>
    );
};

export default Home;