import React, {useContext, useState} from 'react';
import styles from './Roster.module.css'
import {CenterTitle, RosterGrid, ScrollableTimeline} from "../../components";
import {AppContext} from "../../context/AppContext";

const Roster = () => {
    const appData = useContext(AppContext);
    let currentDate = new Date();
    const [year, setYear] = useState(currentDate.getFullYear().toString());
    return (
        <>
            <section style={{height:"auto"}}>
                <CenterTitle children={"ROSTER"}/>
                <ScrollableTimeline time={appData.rosterList.years} year={year} onChangeYear={setYear}/>
                <RosterGrid year={year}/>

            </section>

        </>
    );
};

export default Roster;