import React, {useEffect, useState} from 'react';
import styles from './Dropdown.module.css';
import {RiArrowDownSLine} from "react-icons/ri";
import Seme from "../../data/img/seme.png";
import FogliolinaGreen from "../../data/img/fogliolina-green.png";
import convertHtmlToReact from "@hedgedoc/html-to-react";

const dropdownStyles = ['dropdownPrimary', 'dropdownSecondary'];
const contentStyles = ['contentPrimary', 'contentSecondary'];
const Dropdown = (props) => {

    const {placeholder, itemList, value, linkConfirmation, onChange} = props;
    const [selected, setSelected] = useState([])

    useEffect(() => {
        setSelected(itemList.filter(x => x.name === value));
    }, [value])

    let imageIcon;
    if(value){
        imageIcon = FogliolinaGreen
    } else {
        imageIcon = Seme
    }

    return (
        <>
            <div className={styles.dropdownContainer}>


                <div className={styles.container}>
                    <div className={styles.selectContainer}>

                        <div className={styles.icon}>
                            <img src={imageIcon} alt={"seme"} className={styles.img}/>
                            </div>
                        <select className={styles.select} name={"service"} onChange={onChange}>
                            <option value="" disabled selected hidden>{placeholder}</option>
                            {itemList.map((x, i) => {
                                return <option className={styles.item} key={i}>{convertHtmlToReact(x.name, true)}</option>
                            })}
                        </select>
                    </div>
                </div>
            </div>
            {selected.length > 0 ? 
                <>
                    {/* <img src={selected[0].image} alt={selected[0].name} ></img> */}

                    <div className={styles.text}>
                        {convertHtmlToReact(selected[0].description, true)}
                    </div> 
                    
                    <div className={styles.text}>
                        {/* <b>Prezzo</b>: {convertHtmlToReact(selected[0].price, true)}  */}
                        {selected[0].discountPrice !== '' ? 
                            <>                                
                                <p className={styles.price}>
                                    <b>Prezzo</b>: <s>{convertHtmlToReact(selected[0].price, true)}</s> <b>{convertHtmlToReact(selected[0].discountPrice, true)}</b><br />
                                    <span className={styles.promoprice}>(Prezzo in promozione temporanea)</span>
                                </p>
                            </> 
                        : <><p className={styles.price}><b>Prezzo</b>: {convertHtmlToReact(selected[0].price, true)}</p></> }
                    </div>
                    <a href={selected[0].ctaLink} target="_blank">
                        <button type="button" class="black">{convertHtmlToReact(selected[0].ctaLabel, true)}</button>
                    </a>
                    <br/>
                    {selected[0].ctaLink2 !== "" ? 
                            <>   
                                <a href={selected[0].ctaLink2} target="_blank">
                                    <button type="button" class="green">Richiedi preventivo</button>
                                </a> 
                            </> 
                    : null}                            
                    <br />
                </> 
            : null}
        </>
       )
};

export default Dropdown;